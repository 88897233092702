import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError, switchMap, mergeMap, of, delay, retryWhen } from 'rxjs';
import { environment } from 'src/environments/environment';
import { List } from 'lodash';
import { TitleSummary } from 'src/app/models/title.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiURL = environment.backendApiUrl;
  private authentication = '/common/token';
  private titles = '/titles';
  private columnsCustom = '/user/tables/columns/customization';
  private titleSearchSubstring = '/titles/request/talent-name/search';
  private titleMetadata = '/titles/metadata';
  private titleRequestCommon = '/titles/request/common';
  private titleRequestTerritory = '/titles/request/ratings';
  private userRole = '/user/roles';
  private titleRequestNameSearch = '/talents/names/search';
  private titleRequestTitleValidation = '/titles/request/validate/title';
  private titleRequestTagsByGenre = '/titles/request/tags/top-ten';
  private getTitleRequest = '/titles/request';
  private saveTitleRequest = '/titles/request/save';
  private email = '/common/email/send';
  private titleDeleteValidationMethod = '/titles/maintenance/delete/validate';
  private executeSP = '/common/stored-proc-runner';
  private requests = '/requests';
  private requestsMassUpdate = '/requests/mass-update';
  private imageControl = '/common/signed-url/poster';
  private titleSummary = '/titles/maintenance/summary/title';
  private titleSummaryTab = '/titles/domestic/maintenance/summary';
  private validateTitleSummaryTab = '/titles/domestic/maintenance/summary/validate';
  private sfDelete = '/titles/maintenance/delete';

  private token: string | null = null;
  private tokenExpiry: Date | null = null;

  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      console.log(error);
      console.log(error.message);
      console.error(`Status ${error.status}, body was: `, error.message);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(error.error.error ?? 'Connection Error'));
  }

  // Custom retry strategy that does not retry on 400 errors and stops after exponential backoff maxRetries
  private retryStrategy(maxRetries: number = 3, scalingDelay: number = 1000) {
    return (attempts: Observable<HttpErrorResponse>) => {
      return attempts.pipe(
        mergeMap((error: HttpErrorResponse, retryCount: number) => {
          if (error.status === 400) {
            console.log('Lambda returned 400 error, stopping retry: ', error.message);
            return throwError(() => error);
          } else if (retryCount >= maxRetries) {
            console.log('Max retry count reached, not retrying anymore: ', error.message);
            return throwError(() => error);
          } else return of(error).pipe(delay(scalingDelay * Math.pow(2, retryCount)));
        })
      );
    };
  }

  private isTokenValid(): boolean {
    if (!this.token || !this.tokenExpiry) {
      return false;
    }
    return new Date().getTime() < new Date(this.tokenExpiry).getTime();
  }

  public updateApiToken(token: string, token_expiry: Date) {
    this.token = token;
    this.tokenExpiry = token_expiry;
  }

  private handleApiCall<T>(apiCall: () => Observable<T>): Observable<T> {
    if (!this.isTokenValid()) {
      return this.validateAuthToken(localStorage.getItem('access_token')!).pipe(
        switchMap((tokenResponse) => {
          this.token = tokenResponse.api_token;
          this.tokenExpiry = tokenResponse.api_token_expiry; // Setting token expiry to 2 hours from now
          // No need to call http.get again, just reissue apiCall with updated token
          return apiCall();
        }),
        catchError(this.handleError)
      );
    }
    return apiCall();
  }

  public authToken(code: string, redirect_uri: string): Observable<any> {
    const query = `?auth=code&code=${code}&redirect_uri=${redirect_uri}`;
    const apiCall = () =>
      this.http
        .post<any>(`${this.apiURL}${this.authentication}${query}`, {
          headers: new HttpHeaders(),
          observe: 'body',
          responseType: 'json',
        })
        .pipe(retryWhen(this.retryStrategy()), catchError(this.handleError));
    console.log(apiCall);
    return apiCall();
  }

  public validateAuthToken(token: string): Observable<any> {
    const query = `?auth=token&token=${token}`;
    const apiCall = () =>
      this.http
        .post<any>(`${this.apiURL}${this.authentication}${query}`, {
          headers: new HttpHeaders(),
          observe: 'body',
          responseType: 'json',
        })
        .pipe(retryWhen(this.retryStrategy()), catchError(this.handleError));
    return apiCall();
  }

  public getTitlesList(): Observable<any> {
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titles}`, {});

    return this.handleApiCall(apiCall);
  }

  public getColumnCustomizations(sso: string, control_route: string, control_id: string): Observable<any> {
    const query = `?sso=${sso}&control_route=${control_route}&control_id=${control_id}`;
    console.log(query);

    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.columnsCustom}${query}`, {});

    return this.handleApiCall(apiCall);
  }

  public putColumnCustomizations(personalization: JSON): Observable<any> {
    const apiCall = () => this.http.put<any>(`${this.apiURL}${this.columnsCustom}`, personalization, {});

    return this.handleApiCall(apiCall);
  }

  public getTitleSearchSubstring(search: string): Observable<any> {
    const query = `?substring=${search}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleSearchSubstring}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  public getTitleMetadata(title_id: string): Observable<any> {
    const query = `?title_id=${title_id}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleMetadata}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getTitleRequestCommonData(sso: string): Observable<any> {
    const query = `?sso=${sso}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleRequestCommon}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getPosterImage(image: number): Observable<Blob> {
    const query = `?object_key=${image}.jpeg`;
    
    const apiCall = () => this.http.get<Blob>(`${this.apiURL}${this.imageControl}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getTitleRequestTerritoryData(territory_id: string): Observable<any> {
    const query = `?territory_id=${territory_id}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleRequestTerritory}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getUserRole(sso: string): Observable<{ role: string }> {
    const query = `?sso=${sso}`;
    const apiCall = () => this.http.get<{ role: string }>(`${this.apiURL}${this.userRole}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getTitleRequestNameSearch(talent_name: string): Observable<any> {
    const query = `?talent_name=${talent_name}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleRequestNameSearch}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  //TODO: Fix CORS issues on this endpoint? Might just keep using the title validate endpoint instead
  getTitleRequestImdbValidation(imdb: string): Observable<any> {
    const query = `?value=${imdb}&validation=imdb`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleRequestTitleValidation}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getTitleRequestTitleValidation(title: string): Observable<any> {
    const query = `?value=${title}&validation=title`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleRequestTitleValidation}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  getRequestByID(id: string): Observable<any> {
    const query = `?request_queue_id=${id}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.getTitleRequest}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  //TODO: Implement this endpoint
  /*
    request_queue_id = parameters.get("request_queue_id")
    from_date = parameters.get("from_date")
    to_date = parameters.get("to_date")
    request_status = parameters.get("request_status", "Pending")
    request_type = parameters.get("request_type", "title")
    search_string = parameters.get("search_string")
  */
  // getFilteredRequests(
  //   request_queue_id?: Number,
  //   from_date?: string,
  //   to_date?: string,
  //   request_status?: string,
  //   request_type?: string,
  //   search_string?: string
  // ): Observable<any> {
  //   const apiCall = () =>
  //     this.http
  //       .get<any>(`${this.apiURL}${this.getRequests}${query}`, {
  //         headers: new HttpHeaders({
  //           Authorization: `Bearer ${this.token}`,
  //         }),
  //         observe: 'body',
  //         responseType: 'json',
  //       })
  //       .pipe(retryWhen(this.retryStrategy()), catchError(this.handleError));
  //   var results = apiCall();
  //   return this.handleApiCall(apiCall);
  // }

  getTopTenTagsByGenre(genre_id: number): Observable<any> {
    const query = `?genre_id=${genre_id}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleRequestTagsByGenre}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  postTitleRequest(titleRequest: any): Observable<any> {
    const apiCall = () => this.http.post<any>(`${this.apiURL}${this.saveTitleRequest}`, titleRequest, {});
    return this.handleApiCall(apiCall);
  }

  sendEmail(emailConfig: any): Observable<any> {
    const apiCall = () => this.http.post<any>(`${this.apiURL}${this.email}`, emailConfig, {});
    return this.handleApiCall(apiCall);
  }

  /**
   * Deletes a title.
   * @param title_id - The ID of the title to be deleted.
   * @returns An Observable that emits the deletion result.
   */
  public deleteTitle(title_id: string, delete_reason: string, user_name: string, sso: string): Observable<any> {
    const params = [title_id, delete_reason, user_name, sso];
    const body = JSON.stringify({
      sp_name: 'title.delete_title',
      params: params,
    });

    const apiCall = () => this.http.post<any>(`${this.apiURL}${this.executeSP}`, body, {});
    return this.handleApiCall(apiCall);
  }

  /**
   * Validates the deletion of a title.
   * @param title_id - The ID of the title to be deleted.
   * @returns An Observable that emits the validation result.
   */
  public validateTitleDelete(title_id: string): Observable<any> {
    const apiCall = () =>
      this.http.get<any>(`${this.apiURL}${this.titleDeleteValidationMethod}?title_id=${title_id}`, {});
    return this.handleApiCall(apiCall);
  }

  public declineRequest(
    requestId: number,
    reviewerName: string,
    reviewerSSO: string,
    reviewerComments: string
  ): Observable<any> {
    const body = JSON.stringify({
      request_id: requestId,
      updated_by_name: reviewerName,
      reviewer_sso: reviewerSSO,
      reviewer_comments: reviewerComments,
    });
    const apiCall = () => this.http.post<any>(`${this.apiURL}/titles/request/decline`, body, {});
    return this.handleApiCall(apiCall);
  }

  public getFilteredRequestList(
    request_queue_id?: string,
    from_date?: string,
    to_date?: string,
    request_status?: string,
    request_type?: string,
    search_string?: string,
    sort_order?: string
  ): Observable<any> {
    let query = new HttpParams();
    const params = [
      { key: 'request_queue_id', value: request_queue_id },
      { key: 'from_date', value: from_date },
      { key: 'to_date', value: to_date },
      { key: 'request_status', value: request_status },
      { key: 'request_type', value: request_type },
      { key: 'search_string', value: search_string },

      { key: 'sort_by_date_asc', value: sort_order },
    ];

    for (const param of params) {
      if (param.value) {
        query = query.set(param.key, param.value);
      }
    }
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.requests}`, { params: query });
    return this.handleApiCall(apiCall);
  }

  /**
   * approves request and creates title
   * @param Request ID - The ID of the request
   * @param user ID - approvers sso
   * @returns An Observable that emits the title id.
   */
  public approveTitleRequest(request_id: number, sso: string): Observable<any> {
    const params = [request_id, sso];
    const body = JSON.stringify({
      sp_name: 'suspense.approve_local_title_request',
      params: params,
    });

    const apiCall = () => this.http.post<any>(`${this.apiURL}${this.executeSP}`, body, {});
    return this.handleApiCall(apiCall);
  }

  public doRequestsMassUpdate(
    request_ids: List<number>,
    sso: string,
    action: string,
    reviewerComments?: string
  ): Observable<any> {
    const body = JSON.stringify({
      request_ids: request_ids,
      sso: sso,
      action: action,
      reviewerComments: reviewerComments || '',
    });
    console.log('body in doRequestsMassUpdate: ', body);

    const apiCall = () => this.http.post<any>(`${this.apiURL}${this.requestsMassUpdate}`, body, {});
    return this.handleApiCall(apiCall);
  }

  public getTitleSummary(title_id: string): Observable<TitleSummary> {
    const query = `?title_id=${title_id}`;
    const apiCall = () => this.http.get<TitleSummary>(`${this.apiURL}${this.titleSummary}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  public deleteTitleSF(titleId: string): Observable<any> {
    const body = JSON.stringify({ title_id: titleId });

    console.log(body);
    const apiCall = () => this.http.post<any>(`${this.apiURL}${this.sfDelete}`, body, {});
    return this.handleApiCall(apiCall);
  }

  public getTitleSummaryTab(card: string, title_id: string): Observable<any> {
    const query = `?card=${card}&title_id=${title_id}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.titleSummaryTab}${query}`, {});
    return this.handleApiCall(apiCall);
  }

  public titleSummaryTabValidation(title_id: string, field_name: string, field_value: string): Observable<any> {
    const query = `?title_id=${title_id}&field_name=${field_name}&field_value=${field_value}`;
    const apiCall = () => this.http.get<any>(`${this.apiURL}${this.validateTitleSummaryTab}${query}`, {});
    return this.handleApiCall(apiCall);
  }
}
