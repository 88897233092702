<ng-container *ngIf="isLoading; else display">
  <div class="whole-page">
    <div class="center-container">
      <nbcu-loader></nbcu-loader>
    </div>
  </div>
</ng-container>
<ng-template #display>
  <header>
    <div class="header-container">
      <app-header></app-header>
    </div>
  </header>
  <body>
    <div class="side-nav-container">
      <app-side-menu></app-side-menu>
    </div>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </body>
</ng-template>
