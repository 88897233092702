import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  _isLoading = new BehaviorSubject(true);

  constructor() {}

  set isLoading(value: boolean) {
    localStorage.setItem('isLoading', JSON.stringify(value));
    this._isLoading.next(value);
  }

  get isLoading(): boolean {
    return this._isLoading.value;
  }
}
