import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbcuAppBarComponent, AppBarAction } from '@NBCUniversalOTS/tl-shared-storybook-ng';
import { ApiService } from 'src/app/services/api/api.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { RouteReuseStrategy, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, NbcuAppBarComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  sso = localStorage.getItem('uid');
  userTerritory: string | null = null;
  constructor(private apiService: ApiService, private router: Router, private routeReuse: RouteReuseStrategy) {
    // routeReuse.shouldReuseRoute = () => false;
  }

  async checkUserTerritory(): Promise<void> {
    console.log('check func user territory in header');
    if (this.sso) {
      try {
        const result: any = await lastValueFrom(this.apiService.getColumnCustomizations(this.sso, '/admin', 'admin'));
        console.log('User preference loaded header check func', result);
        if (result && result[4].territory) {
          console.log('territory in header check func', result[4].territory);
          this.userTerritory = result[4].territory;
        } else {
          //default to domestic if no user preference exists
          this.userTerritory = 'domestic';
        }
      } catch (error) {
        console.error('Error loading user preferences', error);
      }
    }
  }

  @ViewChild(NbcuAppBarComponent) appBar!: NbcuAppBarComponent;
  project = 'erm';

  actions: AppBarAction[] = [
    { id: 'menu-1', icon: 'notificationsNone', tooltip: 'Menu 1' },
    { id: 'menu-2', icon: 'person', tooltip: 'Menu 2' },
    { id: 'menu-3', icon: 'apps' },
  ];

  searchCategory = 'Titles';
  selectedCategory = 'Titles';
  searchCategories: string[] = ['All', 'Titles', 'Contribution Reports', 'Example'];

  searchCategoryChange(inputName: string, event: any) {
    this.selectedCategory = event;
  }

  //add domestic or international to route based on user preferences
  async searchOptionsChange(inputName: string, event: any) {
    await this.checkUserTerritory();
    this.appBar.clearSearch();
    this.router.navigateByUrl(`/titles/${this.userTerritory}/${event.value}`);
  }

  getOptionsTitles(match: string): Promise<any> {
    return firstValueFrom(this.apiService.getTitleSearchSubstring(match.toUpperCase())).then((response) => {
      console.log('response:', response);
      if (!response || response.length === 0) return [{ value: '-', description: 'No matches found', disabled: true }];
      else return response;
    });
  }

  searchOptions = (match: string | { value: string; description: string }): Promise<any> => {
    if (typeof match === 'object' && 'description' in match) {
      match = match.description;
    }
    if (match && match.length > 2) {
      //Extras to demo how future search API functions should be added
      switch (this.selectedCategory) {
        case 'All':
          return Promise.resolve([{ value: '-', description: 'Please enter at least 3 characters', disabled: true }]);
        case 'Titles':
          return this.getOptionsTitles(match);
        case 'Contribution Reports':
          return Promise.resolve([{ value: '-', description: 'Please enter at least 3 characters', disabled: true }]);
        case 'Example':
          return Promise.resolve([{ value: '-', description: 'Please enter at least 3 characters', disabled: true }]);
        default:
          return Promise.resolve([{ value: '-', description: 'Please enter at least 3 characters', disabled: true }]);
      }
    } else return Promise.resolve([{ value: '-', description: 'Please enter at least 3 characters', disabled: true }]);
  };
}
