import { Router } from '@angular/router';
import { NEVER, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';
import { PendoService } from '../pendo/pendo.service';
import { LoadingService } from '../loading/loading.service';

export interface TokenResponse {
  access_token: string;
  refresh_token?: string; // Include other expected properties as needed
  expires_in: number;
}


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private client_id = environment.clientId;
  private redirectUri = environment.authRedirectUri;
  private authUrl = environment.authUrl;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private pendo: PendoService,
    private loading: LoadingService,
  ) {
    console.log(this.redirectUri);
  }

  login() {
    sessionStorage.setItem('redirectLocation', window.location.href);
    window.location.replace(
      `${this.authUrl}?client_id=${this.client_id}&response_type=code&redirect_uri=${this.redirectUri}`,
    );
  }

  logout() {
    localStorage.clear();
  }

  checkExpiry(): Promise<boolean> {
    const time = Date.now();
    if (time <= Number(localStorage.getItem('expires_in'))) {
      return Promise.resolve(true);
    } else {
      this.logout();
      this.login();
      return Promise.resolve(false);
    }
  }

  async fetchToken(code: string): Promise<boolean> {
    const data = await firstValueFrom(this.apiService.authToken(code, this.redirectUri));

    if (data == NEVER || !data.access_token) {
      this.logout();
      this.login();
      return Promise.resolve(false);
    }

    const time = Date.now();
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('expires_in', String(time + data.expires_in * 1000));
    this.validateToken();

    const redirect = sessionStorage.getItem('redirectLocation');
    if (redirect) {
      const url = new URL(redirect);
      this.router.navigateByUrl(url.pathname + url.search);
    } else this.router.navigate(['/']);
    return Promise.resolve(true);
  }

  async validateToken(): Promise<boolean> {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken || accessToken == null || accessToken == '') {
      console.error('Access Token is not available.');
      this.login();
      return Promise.resolve(false);
    }

    const userInfo = await firstValueFrom(this.apiService.validateAuthToken(accessToken));
    if (!userInfo.access_token) {
      this.logout();
      this.login();
      return Promise.resolve(false);
    }

    localStorage.setItem('api_token', userInfo.api_token);
    localStorage.setItem('api_token_expiry', userInfo.api_token_expiry);
    localStorage.setItem('uid', userInfo.access_token.uid);
    localStorage.setItem('firstname', userInfo.access_token.FirstName);
    localStorage.setItem('lastname', userInfo.access_token.LastName);
    localStorage.setItem('email', userInfo.access_token.Email);

    localStorage.setItem('isLoading', 'false');
    this.loading.isLoading = false;

    this.apiService.updateApiToken(userInfo.api_token, userInfo.api_token_expiry);

    this.pendo.initPendo(
      userInfo.access_token.uid,
      userInfo.access_token.Email,
      userInfo.access_token.FirstName + userInfo.access_token.LastName,
    );
    return Promise.resolve(true);
  }
}
