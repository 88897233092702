<nbcu-app-bar
  id="app-bar"
  name="app-bar"
  [project]="project"
  [actions]="actions"
  [searchCategory]="searchCategory"
  [searchCategories]="searchCategories"
  [searchOptions]="searchOptions"
  (searchOptionsChange)="searchOptionsChange('app-bar', $event)"
  (searchCategoryChange)="searchCategoryChange('app-bar', $event)" />
