import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  constructor() {}

  initPendo(uid: string, email: string, full_name: string) {
    // This function creates visitors and accounts in Pendo
    // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
    // Please use Strings, Numbers, or Bools for value types.
    (window as any).pendo.initialize({
      visitor: {
        id: uid, // Required if user is logged in
        email: email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: full_name, // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: environment.envName, // Highly recommended, required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }
}
