import { Routes } from '@angular/router';
import { SSOAuthGuard } from './guards/sso-auth/sso-auth.guard';
import { unsavedChangesGuard } from './guards/unsaved-changes/unsaved-changes.guard';

export const routes: Routes = [
  {
    path: 'dashboard',
    loadComponent: () => import('./components/dashboard/dashboard.component').then((mod) => mod.DashboardComponent),
    canActivate: [SSOAuthGuard],
  },
  {
    path: 'titles',
    loadComponent: () => import('./components/titles/titles.component').then((mod) => mod.TitlesComponent),
    canActivate: [SSOAuthGuard],
  },
  {
    path: 'titles/request',
    loadComponent: () =>
      import('./components/requests/title-creation-request-form/title-creation-request-form.component').then(
        (mod) => mod.TitleCreationRequestFormComponent,
      ),
    canActivate: [SSOAuthGuard],
  },
  {
    path: 'titles/:title_id',
    loadComponent: () =>
      import('./components/title-maintenance/title-maintenance.component').then((mod) => mod.TitleMaintenanceComponent),
    canActivate: [SSOAuthGuard],
  },
  {
    path: 'requests',
    loadComponent: () => import('./components/requests/requests.component').then((mod) => mod.RequestsComponent),
    canActivate: [SSOAuthGuard],
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('./components/requests/requests-detail/requests-detail.component').then(
            (mod) => mod.RequestsDetailComponent,
          ),
        canDeactivate: [unsavedChangesGuard],
      },
    ],
  },
  {
    path: 'auth-callback',
    loadComponent: () =>
      import('./components/auth-callback/auth-callback.component').then((mod) => mod.AuthCallbackComponent),
    canActivate: [SSOAuthGuard],
  },
  {
    path: 'logout',
    loadComponent: () => import('./components/logout/logout.component').then((mod) => mod.LogoutComponent),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/titles',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
];
