import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { inject } from '@angular/core';

export const SSOAuthGuard: CanActivateFn = (route) => {
  const token = localStorage.getItem('access_token');
  const code = route.queryParams['code'];
  const authenticate: AuthService = inject(AuthService);
  if (token != null) {
    return authenticate.checkExpiry();
  } else if (code) {
    return authenticate.fetchToken(code);
  } else {
    authenticate.login();
    return false;
  }
};
