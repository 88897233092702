export const environment = {
  envName: 'dev',
  production: true,
  clientId: 'ermdev',
  authUrl: 'https://fss.stg.inbcu.com/fss/as/authorization.oauth2',
  backendApiUrl: 'https://api.dev.erm.nbcuni.com/v1',
  authRedirectUri: 'https://dev.erm.nbcuni.com/auth-callback',
  siteUrl: 'https://dev.erm.nbcuni.com',
  supportEmail: 'ermengineeringsupport@nbcuni.com'
};
