import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbcuSidenavComponent, NbcuSidenavLink } from '@NBCUniversalOTS/tl-shared-storybook-ng';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule, NbcuSidenavComponent],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  id = 'side-nav-test';
  sso: string = localStorage.getItem('uid')!;
  role: string = '';

  constructor(private apiService: ApiService) {
    this.checkSSOAndAct();
  }

  links: NbcuSidenavLink[] = [
    {
      id: 'dashboard',
      routerLink: '/dashboard',
      icon: 'home',
      label: 'Dashboard',
      notifications: 2,
      tooltip: 'Dashboard',
      active: false,
    },
    {
      id: 'titles',
      routerLink: '/titles',
      icon: 'theaters',
      label: 'Titles',
      tooltip: 'Titles',
      active: false,
    },
    {
      id: 'requests',
      routerLink: '/requests',
      icon: 'library_add',
      label: this.role.length > 0 ? 'Requests' : 'Approvals',
      tooltip: 'Title Requests',
      active: false,
    },
    {
      id: 'admin',
      routerLink: '/admin',
      icon: 'person',
      label: 'Admin',
      tooltip: 'Admin',
      active: false,
    },
    {
      id: 'settings',
      routerLink: '/settings',
      icon: 'settings',
      label: 'Settings',
      tooltip: 'Settings',
      active: false,
    },
    {
      id: 'logout',
      routerLink: '/logout',
      icon: 'logout',
      label: 'Logout',
      alignBottom: true,
      tooltip: 'Logout user',
      active: false,
    },
  ];
  // Function to check sso and perform action
  checkSSOAndAct() {
    setTimeout(() => {
      if (this.sso) {
        this.getUserRole(this.sso);
      } else {
        this.checkSSOAndAct(); // Call recursively until sso is true
      }
    }, 500); // Adjust the timeout as needed
  }

  async getUserRole(sso: string) {
    this.apiService.getUserRole(sso).subscribe((data: any) => {
      this.role = data.roles[0].label;
      this.links[2].label = this.role.includes('Territory') ? 'Requests' : 'Approvals';
    });
  }
}
